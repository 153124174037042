// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "LocationBrochureNewForm__buttonCreateContainer__yFkYl";
export var column = "LocationBrochureNewForm__column__Wl6jT";
export var documentRow = "LocationBrochureNewForm__documentRow__Lkp6O";
export var editor = "LocationBrochureNewForm__editor__YyFRw";
export var fileNameText = "LocationBrochureNewForm__fileNameText__Q5It4";
export var fileSizeText = "LocationBrochureNewForm__fileSizeText__tDiCk";
export var flex = "LocationBrochureNewForm__flex__kNnNp";
export var flexColumn = "LocationBrochureNewForm__flexColumn__zmlth";
export var flexContainer = "LocationBrochureNewForm__flexContainer__yLpRu";
export var gap1 = "LocationBrochureNewForm__gap1__aouK8";
export var gap2 = "LocationBrochureNewForm__gap2__fNK2L";
export var gap3 = "LocationBrochureNewForm__gap3__qMOcB";
export var gap4 = "LocationBrochureNewForm__gap4__kP1Ai";
export var gap5 = "LocationBrochureNewForm__gap5__z5Y5W";
export var grid = "LocationBrochureNewForm__grid__5BvaX";
export var iconDelete = "LocationBrochureNewForm__iconDelete__LCo_m";
export var iconView = "LocationBrochureNewForm__iconView__Stypl";
export var labelContainer = "LocationBrochureNewForm__labelContainer__gseEx";
export var publishedAtPicker = "LocationBrochureNewForm__publishedAtPicker__EWmlh";
export var row = "LocationBrochureNewForm__row__J2L37";
export var uploadDocumentButton = "LocationBrochureNewForm__uploadDocumentButton__vgOol";