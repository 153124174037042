// extracted by mini-css-extract-plugin
export var column = "LocationBrochureNew__column__Fw2tU";
export var container = "LocationBrochureNew__container__Epzeh";
export var flex = "LocationBrochureNew__flex__JYJyj";
export var flexColumn = "LocationBrochureNew__flexColumn__M4Q1_";
export var gap1 = "LocationBrochureNew__gap1__pK2lU";
export var gap2 = "LocationBrochureNew__gap2__ooNrD";
export var gap3 = "LocationBrochureNew__gap3__ktDEQ";
export var gap4 = "LocationBrochureNew__gap4__f_AX9";
export var gap5 = "LocationBrochureNew__gap5__mptNO";
export var pageContainer = "LocationBrochureNew__pageContainer__ngRC6";
export var pageTitle = "LocationBrochureNew__pageTitle__NF0Ja";
export var row = "LocationBrochureNew__row__yYh6X";